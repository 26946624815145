<template>
    <v-card height="100%" width="100%" class="rounded-lg w-full elevation-0">
        <v-list two-line>
            <v-list-item>
                <v-list-item-avatar class="align-self-start mt-0">
                    <v-img src="@/assets/images/avatars/img-xl.svg"></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="lightsecondary rounded-lg">
                    <v-list-item-title class="px-4 d-flex flex-row text-caption ">
                        <span><strong>{{ post.owner }}</strong></span>
                        <!-- <span class="text-caption px-2 ml-2 rounded-lg subsecondary">Professionnal</span> -->
                        <v-spacer></v-spacer>
                        <span class="text-caption">{{ post.sent_at }}</span>
                    </v-list-item-title>
                    <!-- <v-divider class="mt-2"></v-divider> -->
                    <v-card-text class="py-2" outlined>
                        {{ post.content }}
                    </v-card-text>
                    <!-- <v-progress-circular indeterminate color="primary"></v-progress-circular> -->
                    <!-- <v-card-text class="rounded-lg py-2" v-if="post.imag"> -->
                    <v-card-text class="rounded-lg py-2" v-if="post.imag">
                        <!-- <v-img :src="url_img + image.path + image.name" :lazy-src="url_img + image.path + image.name" -->
                        <v-img :src="post.imag" aspect-ratio="1"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="10em"
                            class="secondary lighten-2">
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular :indeterminate="post.imag?false:true" color="secondary"></v-progress-circular>
                                </v-row>
                        </v-img>
                    </v-card-text>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>

// eslint-disable-next-line object-curly-newline
import { mdiPlay } from '@mdi/js'
export default {
    props: {
        post: {
            type: Object,
            default: () => {
                content: "Default description"
            }
        }
    },
    data: () => ({
        absolute: true,
        overlay: false,
    }),

    methods: {
        goToreferenceLink(value) {
            this.$router.push(link)
        }
    },
    setup() {
        const icons = { mdiPlay }
        return {
            icons
        }
    },
}
</script>

<style lang="scss" scoped>
</style>